/** @flow
 * StratoDem Analytics : seniors-housing
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InterPortfolioComparison from "../../../components/images/InterPortfolioComparison";
import IntraPortfolioRankings from "../../../components/images/IntraPortfolioRankings";
import MarketDataExport from "../../../components/images/MarketDataExport";
import PortfolioAnalysisCoverSlide from "../../../components/images/PortfolioAnalysisCoverSlide";
import MarketSlide from "../../../components/images/MarketSlide";
import Layout from "../../../components/layout";
import SEO from "../../../components/SEO/seo";

import '../../index.scss';
import './snapshot.scss';
import { openHubSpotForm } from "../../../core/utils";

const SeniorsHousing = () => (
  <Layout>
    <SEO
      title="Snapshot by StratoDem Analytics"
      keywords={['real estate research', 'stratodem', 'analytics', 'portfolio analysis', 'demographics', 'senior living', 'seniors housing']}
      description="Snapshot by StratoDem Analytics - Analyze your seniors housing portfolio or market against the market"
    />
    <div className="snapshot">
      <div className="sd-section suite-hero">
        <div className="content-wrapper">
          <div className="content">
            <div className="cta">
              <h3>
                Uncover opportunities others will miss.
              </h3>
              <p>
                Be a smarter operator and investor; create your own Snapshot by StratoDem Analytics
                to compare your portfolio against the seniors housing market.
              </p>
              <button className="btn-tertiary" onClick={(event) => {
                openHubSpotForm(event, 'Request a Portfolio Snapshot');
              }}>
                Build a Snapshot of your portfolio
              </button>
              <button className="btn-secondary" onClick={(event) => {
                openHubSpotForm(event, 'Request a sample Snapshot');
              }}>
                Request a sample
              </button>
            </div>
            <div className="hero-image">
              <PortfolioAnalysisCoverSlide />
              <MarketSlide />
            </div>
          </div>
        </div>
      </div>

      <div className="sd-section the-state-of-the-market">
        <h3>Analysis blindspots across seniors housing</h3>
        <p>
          Many senior living organizations still base land acquisition, development and
          marketing decisions on the surrounding <strong>population age 75+</strong>,
          plus rough estimates on income and home values.
        </p>
        <p>
          But we’ve identified <strong>major drawbacks with this analysis</strong>:
        </p>
        <p style={{marginTop: 28}}>
          <strong>Commonly used data...</strong>
        </p>
        <ul>
          <li>Reports income and net worth for age 75+, <strong>not the age levels when assisted living demand spikes</strong>, and at levels too low to be useful for the stronger market-rate senior housing options  </li>
          <li>Relies on data that the government collected 2-6 years ago</li>
          <li>Provides no meaningful competitive advantage or insights, so the herd with the same data all flock to the same locations</li>
        </ul>
        <p style={{marginTop: 28}}>
          <strong>StratoDem Analytics Snapshot - sophisticated & simple</strong>
        </p>
        <ul>
          <li>Generates more precise income analysis for those <strong>age 80+ (or 85+)</strong>, including total income ($35K+, $50K+, $75K+, $100K+, $200k+), retirement income, pension income, net worth, etc.</li>
          <li>Creates the most up-to-date Nowcasts of current economic and demographic estimates, plus more accurate machine-learning-driven forecasts</li>
          <li>Provides competitive advantage through unmatched meshing of 200+ data sources, only attainable with the use of data science for the most comprehensive and complete analysis of your opportunities</li>
        </ul>
      </div>

      <div className="sd-section in-your-custom-market-report">
        <h3>In your Portfolio Snapshot</h3>
        <Row>
          <Col md={4} className="highlight-1">
            <div>
              <IntraPortfolioRankings />
              <p><strong>Forecast and historical growth for each market area, including:</strong></p>
              <ul>
                <li>Income-qualified seniors</li>
                <li>Households age 80+ (or 85+) with higher net worth qualifications</li>
                <li>Adult children with higher income levels</li>
                <li>Assisted living need</li>
              </ul>
            </div>
          </Col>
          <Col md={4} className="highlight-2">
            <div>
              <InterPortfolioComparison />
              <p><strong>Analysis of your portfolio against the market, including:</strong></p>
              <ul>
                <li>Seniors housing REITs</li>
                <li>Senior living operators</li>
                <li>Indexes of seniors housing entities</li>
              </ul>
            </div>
          </Col>
          <Col md={4} className="highlight-3">
            <div>
              <MarketDataExport />
              <p><strong>Rankings for every selected market area based on:</strong></p>
              <ul>
                <li>Population and households age 80+</li>
                <li>Household income and net worth characteristics</li>
                <li>Forecast growth in qualified senior households</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      <div className="sd-section cta">
        <h3 style={{textAlign: 'center'}}>
          Evaluate seniors housing acquisition targets or
          monitor your portfolio with the demand metrics that matter.
        </h3>
        <Row style={{textAlign: 'center', marginTop: 42}}>
          <button className="btn-tertiary" onClick={(event) => {
            openHubSpotForm(event, 'Request your Portfolio Snapshot');
          }}>
            Build a Snapshot of your portfolio
          </button>
        </Row>
      </div>
    </div>
  </Layout>
);

export default SeniorsHousing;
